import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import( '../views/PayView.vue')
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: () => import( '../views/DeliveryView.vue')
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import( '../views/PartnersView.vue')
  },
  {
    path: '/cert',
    name: 'cert',
    component: () => import( '../views/CertView.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import( '../views/CartView.vue')
  },
  {
    path: '/shell',
    name: 'catalog',
    component: () => import( '../views/CatalogView.vue')
  },
  {
    path: '/shell-today',
    name: 'catalog in stock',
    component: () => import( '../views/InStockView.vue')
  },
  {
    path: '/thanks',
    name: 'Thank you!',
    component: () => import( '../views/ThankYouView.vue')
  },
  {
    path: '/:id',
    component: () => import( '../views/ItemView.vue')
  },
  {
    path: '/promo',
    name: 'promo',
    component: () => import( '../views/CatalogPdfView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from) => {
//   if(to.path.slice(0,2) == '/5'){
//     console.log('to')
//   }
// })


export default router
