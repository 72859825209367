<template>
    <div class="row" id="offers">
        <div class="column">
          <div id="promoLeft">
            <h1>На складе</h1>
            <h4>в Москве</h4>
            <div><a href="/shell-today">Подробнее</a></div>
            <img :src="require('@/assets/images/tanks.png')" >
          </div>
        </div>
        <div class="column">
          <div id="promoRight">
            <h1>Каталог</h1>
            <h4>масел Shell</h4>
            <div><a href="/shell">Подробнее</a></div>
            <img :src="require('@/assets/images/tg_image_2138327154.png')" alt="discount">
          </div>
        </div>
    </div>
</template>