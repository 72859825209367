<template>
  <NavBar />
  <router-view />
  <div :style="'width: 100%; text-align:center'">
    <FooterBar />
  </div>
</template>
<script>
import NavBar from './components/NavBar.vue';
import FooterBar from './components/FooterBar.vue';
import { defineComponent, onMounted } from 'vue';
import VueYandexMetrika from 'vue-yandex-metrika';

export default defineComponent ({
setup(){

    onMounted(VueYandexMetrika, {
        id: 93174655,
        options: {
            clickmap:true, 
            trackLinks:true, 
            accurateTrackBounce:true,
        },
        env: process.env.NODE_ENV
        // other options
    })
},
  components: {
    NavBar,
    FooterBar,
  }
})
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@font-face {
  font-family: Evolventa;
  src: url(./assets/css/fonts/Evolventa-Regular.otf);
}

* {
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
  box-sizing: border-box;
}

html {
  font-family: "Evolventa";
  background: #f0f0f0;
  margin: 0;
  position: relative;
  height: 100%;
}

body {
  outline: none;
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
  white-space: nowrap;
  text-align: center;
  font-size: 0;
}
body::before {
  /* height: 100%; */
  display: inline-block;
  vertical-align: middle;
  content: "";
}

h1 {
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  line-height: 80%;
}

a {
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
svg {
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
}

#carousel {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none; /* Hide the scrollbar for MS Edge */
  scrollbar-width: none; /* Hide the scrollbar for Mozilla Firefox */
  /* width: 100%;
    max-width: 1300px; */
  height: 400px;
  /* border: solid #ffffff .5px; */
}

#carousel::-webkit-scrollbar {
  display: none; /* Hide the scrollbar on Webkit based browsers (Chrome, Safari, etc) */
  -webkit-overflow-scrolling: touch; /* On touch screens the content continues to scroll for a while after finishing the scroll gesture */
}

.logo {
  width: 100%;
}

.logo td {
  margin-left: 5%;
}

.logo img {
  /* margin: 3px; */
  max-width: 100px;
  width: fit-content;
}

.logo p {
  text-decoration: none;
  letter-spacing: 2px;
  font-size: 18px;
  color: #c6cfe2;
  position: relative;
  padding-bottom: 20px;
  margin: 0px 34px 0 30px;
  text-transform: uppercase;
  display: inline-block;
  transition: color 0.2s;
}

.icon img {
  margin-left: 40%;
  margin-right: 40%;
  width: 200px;
}

.container {
  display: inline-block;
  white-space: normal;
  vertical-align: middle;
  text-align: left;

  margin: 80px 1% 2px 1%;
  /* background: #fff; */
  width: 98%;
  height: 100%;
  max-width: 1380px;
}
.foreground,
.list {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  max-width: 1380px;
  padding-bottom: 40px;
}

.list h1 {
  margin-top: 0;
  margin-left: 36px;
  padding-top: 30px;

  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 100%;
  /* identical to box height, or 60px */

  color: #000000;
}

hr {
  color: #ffffffe7;
}

/* #catalog {
    padding-top: 30px;
    margin-left: 2.5%;
} */
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.tab_menu:target {
  visibility: visible;
  opacity: 1;
}

.button_white {
  display: table-cell;
  vertical-align: middle;
  border-radius: 5px;
  background-color: #fff;
  width: 200px;
  height: 50px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #000000;
}

.button_white:hover {
  border-radius: 5px;
  background: transparent;
  border: 1px solid #ffffff;
  width: 200px;
  height: 50px;
}

.button_blue {
  width: 165px;
  height: 40px;
  background: #0071e3;
  border-radius: 5px;
  border: none;
  text-align: center;
  position: relative;
  color: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  color: #ffffff;
}
.button_blue p {
  margin: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  align-items: center;
  text-align: center;

  color: #ffffff;
}
.button_blue:hover {
  color: #0071e3;
  border: 1px solid #0071e3;
  background: transparent;
}
.button_blue p:hover {
  color: #0071e3;
}

.landing {
  width: 100%;
  border-radius: 20px;
  /* background-color: #197e00d2; */
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(./assets/css/backgrounds/shell-hero.png) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  max-width: 1380px;
  height: 600px;
  position: relative;
}
.landing h1 {
  /* margin-top: 18.8%; */
  margin-left: 40px;
  margin-bottom: 0;

  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 5.8vw;
  line-height: 90%;
  /* or 64px */
  color: #ffffff;
}
#landing-p-text {
  max-width: 417px;
  margin-top: 22px;
  margin-left: 40px;
}
.landing p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  color: #ffffff;
}
.landing img {
  float: right;
  margin-top: 60px;
  width: 100%;
  max-height: 500px;
}
.landing button {
  border-radius: 5px;
  background-color: #fff;
  width: 200px;
  height: 50px;
  margin-top: 14px;
  margin-left: 40px;
}
.landing button p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #000000;
}

#offers {
  min-height: 300px;
  max-height: 366px;
  max-width: 1380px;

  width: 100%;
  height: 44%;
}
#promoLeft {
  position: relative;
  margin-top: 1px;
  margin-right: 1px;
  height: 100%;
  background: #000000;
  border-radius: 20px;
}
#promoLeft h1 {
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 100%;
  /* identical to box height, or 35px */

  color: #ffffff;
  margin-top: 35px;
  margin-left: 40px;
  margin-bottom: 0;
}
#promoLeft h4 {
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 20px */
  color: #ffffff;
}
#promoLeft div,
#promoRight div {
  margin-top: 16px;
  margin-left: 40px;
}
#promoLeft p {
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  color: #ffffff;
}
#promoLeft a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  text-decoration-line: underline;
  color: #0071e3;
}
#promoLeft img {
  position: absolute;
  width: 50%;
  bottom: 10%;
  right: 5%;
  border-bottom-right-radius: 30px;
}
#promoRight {
  position: relative;
  margin-left: 1px;
  margin-top: 1px;
  background: #000000;
  border-radius: 20px;
  height: 100%;
}
#promoRight h1 {
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 100%;
  /* identical to box height, or 35px */

  color: #ffffff;
  margin-top: 35px;
  margin-bottom: 0;
  margin-left: 40px;
}
#promoRight h4 {
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 20px */
  color: #ffffff;
}
#promoRight p {
  margin-top: 15px;
  margin-bottom: 0;
  margin-left: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  color: #ffffff;
}
#promoRight a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  text-decoration-line: underline;
  color: #0071e3;
}
#promoRight img {
  max-width: 320px;
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: auto;
  bottom: 0%;
  right: 0;
  border-radius: 20px;
}

/* Catalog styles */
.catalog {
  margin-top: 2px;
  /* background-color: #fff; */
  border-radius: 20px;
  max-height: 688px;
}
.catalog h1 {
  font-size: 60px;
  margin: 0;
  padding-top: 3.25%;
  /* padding-left: 2.6%; */
  padding-bottom: 2.6%;
}
.catalogTable {
  width: 100%;
  margin-right: 0;
  /* margin-left: 2.6%;
    margin-right: 2.6%; */
}
.catalog table {
  width: 100%;
}
.catalog td {
  padding: 0;
  width: 25%;
  max-width: 344px;
}
.cardTall {
  background-color: #000;
  width: calc(100% - 1px);
  height: 512px;
  max-width: 340px;
  border-radius: 20px;
  position: relative;
}
.cardTall h2,
.cardShort h2,
.cardLong h2 {
  margin: 0;
  padding: 25px;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 100%;
  /* identical to box height, or 28px */

  color: #ffffff;
}
.cardTall img {
  width: 100%;
}
.cardShort,
.cardLong {
  width: 100%;
  background: #000;
  height: 255px;
  border-radius: 30px;
  position: relative;
}
#shell img {
  position: absolute;
  bottom: 0;
  margin: 10% 5% 20% 5%;
  width: 90%;
}

#partners img {
  position: absolute;
  bottom: 20%;
  margin: 10% 9% 15% 9%;
  width: 82%;
}

/* End of catalog styles */

.history {
  margin-top: 2px;
  border-radius: 20px;
  height: 569px;
  background-color: rgb(212, 230, 245);
  position: relative;
}
.historyHeader {
  margin: 0;
  padding-top: 42px;
  margin-left: 40px;
  padding-right: 250px;
  /* text-align: right; */
}
.historyHeader h1 {
  margin: 0;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 90%;
  /* or 54px */
  color: #00000091;
}
.historyText {
  max-width: 374px;
  margin-left: 40px;
  margin-top: 28px;
}
.historyText p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  color: #00000091;
}
.historyText a {
  margin-top: 17px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  text-decoration-line: underline;

  color: #ffffff;
}
.history img {
  width: 60%;
  position: absolute;
  bottom: 0;
  right: 1%;

  max-width: 736px;
  max-height: 575px;
  object-fit: cover;
}

#details {
  border-radius: 20px;
  margin-top: 2px;
  height: 48%;
  min-width: 300px;
  max-height: 400px;
}
.detailsText {
  height: 144px;
  width: 400px;
  background-color: #fff;
  border-radius: 13px;
  position: absolute;
  bottom: 15px;
  right: 15px;
}
#pay {
  border-radius: 20px;
  width: 100%;
  margin-right: 1px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(./assets/css/backgrounds/Rectangle389.png);
  position: relative;
}
#delivery {
  border-radius: 20px;
  width: 100%;
  margin-left: 1px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(./assets/css/backgrounds/truck.png);
  position: relative;
}
#pay h3,
#delivery h3 {
  margin-left: 30px;
  margin-bottom: 0;
  margin-top: 25px;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 100%;
  /* or 28px */
  color: #191919;
}
#pay p,
#delivery p {
  width: 280px;
  margin-left: 30px;
  margin-top: 11px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  color: #191919;
}

.questions {
  margin-top: 2px;
  border-radius: 20px;
  height: 38%;
  max-height: 321px;
  background-color: rgb(212, 230, 245);
}

.questions {
  margin-top: 1px;
  margin-bottom: 1px;
  width: 100%;
  max-width: 1380px;
  height: 321px;
  background-color: rgb(212, 230, 245);
  border-radius: 20px;
  position: relative;
}
#questions-left-column p {
  max-width: 367px;
  width: 80%;
  margin: 19px 0 0 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  color: #00000091;
}
#questions-right-column p,
#questions-right-column a {
  max-width: 412px;
  width: 80%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 160%;
  /* or 18px */

  color: #00000091;
}
#questions-right-column a {
  text-decoration: underline;
}

/* form styles in overlay file */

.questions h1 {
  margin: 43px 0 0 40px;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 90%;
  /* or 54px */

  color: #00000091;
}
.questions img {
  width: 230px;
  height: 207px;
  object-fit: cover;
  position: absolute;
  bottom: 1px;
  right: calc(50% + 45px);
}

.map {
  margin-top: 2px;
  border-radius: 20px;
  min-height: 400px;
  height: 65.3%;
  max-height: 550px;
  /* background: url(backgrounds/Rectangle296.png); */
  position: relative;
}
.map iframe {
  width: 100%;
  border-radius: 20px;
}
.mapText {
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 400px;
  height: 194px;
  background-color: #fff;
  border-radius: 13px;
}
.mapText h3 {
  margin-top: 27px;
  margin-left: 29px;
  margin-bottom: 3px;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 100%;
  /* identical to box height, or 28px */

  color: #191919;
}
.mapText p,
.mapText a {
  margin-top: 8px;
  margin-left: 29px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  color: #000000;
}

.footer {
  margin-top: 2px;
  border-radius: 30px;
  background: #191919;
  border-radius: 20px;
}

.itemCard {
  max-height: 500px;
  width: 400px;
  background-color: rgb(245, 245, 247);
  margin-left: 1%;
  margin-right: 20px;
  border-radius: 11px;
  padding: 0;
  height: 100%;

  display: block;
  overflow: hidden;
}
.itemCard h1 {
  padding-top: 15px;
  font-size: 32px;
  margin-left: 7px;
}
.itemCard p {
  /* color: rgb(161, 181, 200); */
  margin-left: 7px;
  font-size: 22px;
}
.itemCard img {
  margin: 5%;
  width: 95%;
  flex-shrink: 0;
}

#card1 h1,
#card1 p {
  background: linear-gradient(to right, rgb(86, 157, 190), rgb(255, 54, 67));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#card2 p {
  font-size: 22px;
  color: rgb(187, 54, 67);
}

#card3 {
  background-color: #000;
}
#card3 h1 {
  color: #ffffff;
}
#card3 p {
  font-size: 22px;
  color: rgb(228, 168, 242);
}
#card4 {
  background-color: rgb(250, 250, 250);
}
#card4 h1 {
  color: rgb(48, 54, 65);
}
#card4 p {
  font-size: 22px;
  background: linear-gradient(
    to right,
    rgb(48, 54, 65),
    rgba(48, 54, 65, 0.21)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#log {
  margin-left: 2.5%;
  height: 100%;
}

.cart {
  position: relative;
  width: 100%;
  text-align: left;
  display: inline-block;
}
.cart h1 {
  margin-top: 36px;
  margin-left: 40px;
  font-size: 60px;
}

.cartPrice {
  margin-left: 3.5%;
}
.cartPrice h4 {
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 100%;
  /* identical to box height, or 28px */

  color: #191919;
}
.cartSum {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 100%;
  /* identical to box height, or 28px */

  color: #000000;
}
#next {
  margin-top: 36px;
  margin-left: 88px;
  margin-right: 3.5%;
}

.log {
  width: calc(100% - 80px);
  margin-left: 40px;
  margin-right: 40px;
  text-align: left;
  display: inline-block;
}
hr {
  width: 97.5%;
  margin-right: 2.5%;
}
table {
  width: 97.5%;
  /* margin-right: 2.5%; */
}
.log td {
  margin: 0;
}
.cartImgTD {
  min-width: 80px;
  width: 15%;
  max-width: 150px;
  text-align: left;
}
.cartIMG {
  height: 60px;
}

.modelNameTD {
  width: 30%;
}
.modelName {
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  /* or 18px */

  color: #000000;
}
.elementPrice {
  text-align: right;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  /* or 18px */

  color: #000000;
}
.cartButton {
  text-align: right;
}

.optionsTableCards {
  margin-left: 1%;
  width: 99%;
}
.optionsTableCards img {
  /* border-radius: 16px; */
  margin: 0;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  width: 100%;
}

.optionsItem {
  width: 30%;
  margin: 1.5%;
  margin-right: 1.5%;
  text-align: left;
  background-color: #ffffff;
  border-radius: 16px;
  text-align: center;
  position: relative;
  cursor: pointer;
  /* box-shadow: 10px 6px 4px rgba(138, 135, 135, 0.281); */
  /* perspective: 1000px; Remove this if you don't want the 3D effect */
}
.optionsItem h2,
.item h2 {
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */
  text-align: center;

  color: #000000;
}
.optionsItem p {
  width: 95%;
  margin-left: 2.5%;
  padding-bottom: 2.5%;
  color: #383838;
}
.optionsItem img {
  object-fit: contain;
  max-width: 100px;
  max-height: 280px;
}

.optionsItemLanding {
  position: relative;
  height: 250px;
  width: 32%;
  margin: 0.5%;
  margin-right: 0.5%;
  text-align: left;
  background-color: #000;
  border-radius: 30px;
  /* box-shadow: 10px 6px 4px rgba(138, 135, 135, 0.281); */
  /* perspective: 1000px; Remove this if you don't want the 3D effect */
}
.optionsItemLanding img {
  width: auto;
  max-width: 100%;
  max-height: 250px;
  flex-shrink: 0;
}

.optionsItemLanding h2 {
  position: absolute;
  top: 0;
  left: 0;
  margin: 5%;

  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 100%;
  /* identical to box height, or 35px */

  color: #ffffff;
}
#iPhoneItem {
  text-align: right;
}
#iPhoneItem img {
  margin-right: 4%;
}

#macItem img {
  bottom: 0;
  margin-left: 20%;
  width: 80%;
}
#WatchItem img {
  margin-left: 5%;
  max-width: 90%;
  position: absolute;
  bottom: 0;
}
#iPadItem img {
  position: absolute;
  padding: 0;
  right: 0;
  bottom: 0;
}
#AirPodsItem {
  text-align: right;
  position: relative;
}
#AirPodsItem img {
  width: 40%;
  margin-top: 10%;
  margin-right: 2.5%;
  border-radius: 30px;
}

.item {
  min-width: 200px;
  max-width: 250px;
  margin: 15px;
  margin-right: 15px;
  font-size: 30px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #1919191e;
  /* transition: box-shadow 0.3s ease-in-out;
    box-shadow: 10px 6px 4px rgba(138, 135, 135, 0.281); */
}
.item button {
  margin-bottom: 2%;
}

.item img {
  border-radius: 30px;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
}

#item_photo_img {
  text-align: center;
}

.item_wide {
  width: 100%;
  min-width: 700px;
  max-width: 1380px;
}

.item_wide img {
  height: auto;
  min-width: 200;
  max-width: 400px;
  flex: content;
}
.item_wide div {
  padding: 0;
}

.cart button {
  margin-left: 2.5%;
}

.order_info {
  width: 90%;
  margin-top: 30px;
}
.order_info p {
  width: 70%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  /* or 19px */

  color: #000000;
}
.order_info h5,
.order_info a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* or 14px */
  text-decoration-line: underline;

  color: #000000;
}

.cl-btn-7 {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  position: absolute;
  top: 0;
  right: 1%;
  z-index: 1;
  margin: 20px auto;
  cursor: pointer;
}

.cl-btn-7:before {
  content: "+";
  /* 9AD0EC */
  color: #0071e3;
  position: absolute;
  z-index: 2;
  transform: rotate(45deg);
  font-size: 50px;
  line-height: 1;
  top: -5px;
  left: 7px;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.2, 0.85);
}

.cl-btn-7:after {
  color: #fff;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: #0071e3;
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.2, 0.85);
  transform: scale(0.01);
}

.cl-btn-7:hover:after {
  transform: scale(1);
}

.cl-btn-7:hover:before {
  transform: scale(1) rotate(45deg);
  color: #fff;
}

.cl-btn-8 {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  position: absolute;
  top: 0;
  left: 1%;
  z-index: 1;
  margin: 20px auto;
  cursor: pointer;
}

.cl-btn-8:before {
  content: "-";
  /* 9AD0EC */
  color: rgba(215, 141, 141, 0.931);
  position: absolute;
  z-index: 2;
  transform: rotate(0deg);
  font-size: 50px;
  line-height: 1;
  top: -7px;
  left: 9px;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.2, 0.85);
}

.cl-btn-8:after {
  color: #551818;
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: rgba(215, 141, 141, 0.809);
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.2, 0.85);
  transform: scale(0.02);
}

.cl-btn-8:hover:after {
  transform: scale(1);
}

.cl-btn-8:hover:before {
  transform: scale(0.8) rotate(0deg);
  color: #551818;
}

.cl-btn-9 {
  position: absolute;
  top: 0px;
  right: 2%;
  cursor: pointer;
  background: transparent;
}
.cl-btn-9 p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: rgba(235, 78, 61);
  background: transparent;
}

.cl-btn-9 p:hover {
  color: rgba(235, 79, 61, 0.682);
}

.cl-btn-10 {
  position: absolute;
  top: 0;
  right: 1%;
  cursor: pointer;
  background: transparent;
  margin-right: 2px;
}
.cl-btn-10 p {
  color: rgba(66, 136, 247);
  background: transparent;
}

.cl-btn-10 p:hover {
  color: rgba(66, 136, 247, 0.682);
}

.photo {
  width: 100%;
  text-align: center;
}
.photo img {
  margin-top: 5%;
  max-width: 450px;
  object-fit: cover;
}

.description {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: 80%;
  flex: 1;
}

.colorPicker,
.memoryPicker {
  width: 100%;
  margin-top: 35px;
  /* max-width: 950px;
    margin-left: 3.25%;
    padding-top: 6.5%;
    width: 100%; */
  padding: 0;
  display: inline;
  text-align: left;
}
.colorPicker input,
.memoryPicker input {
  max-width: 80px;
  display: none;
}

.colorPicker li,
.memoryPicker li {
  text-align: center;
  /* background: #F7F7F7; */
  border: 1px solid rgb(209, 209, 209);
  border-radius: 4px;
  min-height: 40px;
  width: 78px;
  float: left;
  display: inline;
  margin-bottom: 3%;
  /* margin-left: 3.25%; */
  margin-right: 12px;
}
.colorPicker h3,
.memoryPicker h3 {
  width: 100%;
  margin: 0;
  margin-bottom: 18px;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */

  color: #191919;
}
.colorPicker label,
.memoryPicker label {
  text-align: center;
  position: relative;
}
.memoryPicker label p,
.colorPicker label p {
  padding-top: 12px;
}
.colorPicker span,
.memoryPicker span {
  vertical-align: middle;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  text-align: center;

  color: rgb(209, 209, 209);
}
.colorPicker img {
  margin-top: 10.67px;
  margin-bottom: 10.67px;
  max-width: 50px;
  object-fit: cover;
}

/* .colorPicker span {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4))
} */

.colorPicker input:checked + label span,
.memoryPicker input:checked + label span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  text-align: center;

  color: #ffffff;
}
#secondOptPicker li {
  width: 168px;
  height: 40px;
}
.item-page-buttons {
  margin-top: 40px;
  display: inline;
}
#buy-now {
  width: 210px;
  border: 1px solid #0071e3;
  background-color: #0071e3;
  text-align: center;
  border-radius: 5px;
  padding-top: 11px;
  padding-bottom: 12px;
  margin-right: 10px;
  color: #ffffff;
  display: inline-block;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

#buy-now:hover {
  background-color: white;
  border: 1px solid #0071e3;
  color: #0071e3;
}

#add-to-cart {
  width: 165px;
  padding-top: 5px;
  border: 1px solid #0071e3;
  background-color: #fff;

  text-align: center;
  border-radius: 5px;
  padding-top: 11px;
  padding-bottom: 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #0071e3;
  display: inline-block;
}

.add,
.info {
  max-width: 770px;
  margin-left: 6.5%;
  margin-top: 7%;
}
.info p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #000000;
}

#price {
  margin-left: 0;
  margin-top: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */

  color: #191919;
}
.addButton {
  width: 298px;
  height: 50px;
  border: none;
  background: #000000;
}
.addButton p {
  margin-top: 15px;
  margin-bottom: 17px;
  height: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */
  text-align: center;
  color: #ffffff;
}

.title,
.price {
  width: 95%;
  margin-right: 5%;
  text-align: left;
}
.title h2 {
  margin-left: 0;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 110%;
  /* or 28px */

  color: #191919;
}
.title h4 {
  margin-left: 5%;

  font-size: 20px;
  color: rgba(0, 0, 0, 0.524);
}

.loginPage {
  text-align: center;
  width: 100%;
  height: 100%;
}
.loginPage form {
  margin-left: 15%;
  margin-top: 15%;
  padding-bottom: 5%;
  height: 70%;
  width: 70%;
  border-radius: 16px;
  border: #999 solid 1px;
}
.loginPage input {
  width: 80%;
}
.loginPage a {
  color: #2e8ebe;
}

#memoryLabel {
  width: 100%;
}

.form_radio_btn {
  margin-right: 1%;
  margin-left: 2%;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 5px 3px 2px rgba(138, 135, 135, 0.281);
  display: inline-block;
  margin-top: 27px;
  width: 100px;
  height: 40px;
}

.form_radio_btn input[type="radio"] {
  border-radius: 16px;
  width: 100%;
  display: none;
}

.form_radio_btn label {
  float: left;
  box-shadow: 5px 3px 2px rgba(138, 135, 135, 0.281);
  background: #ffffff;
  display: block;
  cursor: pointer;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 12px;
  padding-bottom: 12px;

  /* line-height: 34px; */
  border: 3px solid #999;
  border-radius: 16px;
  user-select: none;
}

/* Checked */
.form_radio_btn input[type="radio"]:checked + label {
  border: 3px solid #184155;
  color: #fff;
  background-color: #607d8c;
}

/* Hover */
.form_radio_btn label:hover {
  color: #707070ef;
}

.next_button {
  width: 99%;
  text-align: right;
  margin-right: 1%;
}
.next_button button {
  width: 20%;
  text-align: center;
}

.advert {
  margin-top: 200px;
  min-width: 700px;
  max-width: 100%;
  margin-right: 55px;
  margin-bottom: 200px;
}
.block {
  height: 378px;
  background: #d9d9d9;
  border-radius: 30px;
}
#blockLeft {
  margin-right: 20px;
}
#blockRight {
  margin-left: 20px;
}

.footer {
  width: 100%;
  background-color: #000;
  height: 293px;
}
#footer-mobile {
  display: none;
}
.footer h2 {
  position: absolute;
  width: 108px;
  height: 24px;

  margin-top: 52px;
  margin-left: 200px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #ffffff;
}

#pp {
  margin-top: 80px;
  position: absolute;
  width: 249px;
  height: 19px;
  left: 200px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.footerMenu {
  margin-top: 1px;
  margin-bottom: 10px;
  background: #191919;
  border-radius: 20px;
}
.communication {
  padding-top: 35px;
  margin-left: 2.5%;
}
.communication h1 {
  padding-bottom: 26px;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 90%;
  /* or 54px */
  color: #ffffff;
}
.communication p,
.communication a {
  width: auto;
  display: inline;
  padding: 6px 20px 6px 20px;
  background: rgb(212, 230, 245);
  border-radius: 20px;
  margin-right: 66px;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 100%;
  /* identical to box height, or 28px */
  color: #00000091;
}
.footerTable {
  width: 80%;
  margin: 2.5%;
}
.footerTable tbody {
  vertical-align: baseline;
}
.footerTable td {
  width: fit-content;
}
.footerTable a {
  margin-bottom: 12px;
  display: block;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;

  opacity: 0.8;
}
#firstColumn {
  width: 30%;
}
#secondColumn,
#thirdColumn {
  width: 20%;
}
#label {
  font-family: "Michroma";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */
  text-transform: uppercase;
}
#privacyRef,
#designerRef {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #cfcfcf;
  opacity: 0.8;
}
#designerRef {
  letter-spacing: -0.03em;
  text-transform: uppercase;
}
.remove-from-cart-btn {
  color: #a4a4a4;
  font-size: 15px;
  width: 20px;
  height: 20px;
  border: 1px solid #a4a4a4;
  border-radius: 10px;
}

.slider {
  width: 100%;

  overflow: hidden;
}

.slides {
  display: flex;

  overflow-x: auto;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  /*
    scroll-snap-points-x: repeat(300px);
    scroll-snap-type: mandatory;
    */
}
.slides::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.slides::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
}
.slides::-webkit-scrollbar-track {
  background: transparent;
}
.slides > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100%;
  height: 500px;
  margin-right: 50px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}

.slider > a {
  display: inline-flex;
  width: 1.5rem;
  height: 1.5rem;
  background: white;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0 0.5rem 0;
  position: relative;
}
.slider > a:active {
  top: 1px;
}
.slider > a:focus {
  background: #000;
}
#slide-1,
#slide-2,
#slide-3 {
  width: 1380px;
}

.optionsItem {
  display: inline-block;
  min-width: 200px;
  min-height: 400px;
  width: 25%;
  margin: 4.16%;
  text-align: left;
  background-color: rgb(250, 250, 250);
  border-radius: 16px;
  text-align: center;
  position: relative;
  box-shadow: 1px 0px 3px rgb(209, 209, 209);
  /* box-shadow: 10px 6px 4px rgba(138, 135, 135, 0.281); */
  /* perspective: 1000px; Remove this if you don't want the 3D effect */
}

.optionsItem:hover {
  box-shadow: 1px 0px 7px rgb(209, 209, 209);
}
.optionsItem h2,
.item h2 {
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */
  text-align: center;

  color: #000000;
}
.optionsItem hr {
  width: 90%;
  margin: 0 5%;
}
.optionsItem p {
  font-size: 14px;
  width: 95%;
  margin-left: 5%;
  padding-bottom: 14px;
  color: #383838;
}
#entry {
  height: 50px;
}
.optionsItem > div {
  text-align: left;
}
.optionsItem.price {
  font-weight: 800;
}
.optionsItem img {
  margin-top: 10px;
  object-fit: contain;
  max-width: 220px;
  max-height: 280px;
}
.optionsItem button {
  width: 70%;
  position: absolute;
  bottom: 10px;
  left: 15%;
}
.optionsItem a {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 12px;
}
.optionsItem a svg {
  color: #626262;
}
#delivery-index {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.4)),
    url(./assets/css/backgrounds/truck.png) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
#payment-index {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(./assets/css/backgrounds/Rectangle389.png) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
#more-about {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)),
    url(./assets/css/backgrounds/mre-shell.png) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.text {
  width: calc(100% - 80px);
  margin: 0 40px;
}
.text h2 {
  margin-top: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  /* or 19px */

  color: #000000;
}
.text p,
.partner-card p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  /* or 19px */

  color: #000000;
}

.partner-card {
  text-align: center;
  width: 100%;
  min-height: 300px;
  padding: 10px 0;
}
.partner-card img {
  width: 90%;
  max-width: 300px;
  object-fit: cover;
}
.certificates-table {
  padding: 10px 40px;
}
.table-p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  /* or 19px */

  color: #000000;
}

@media screen and (min-width: 1400px) {
  .landing h1 {
    font-size: 80px;
  }
}
@media screen and (max-width: 1300px) {
  .questions img {
    width: 168px;
    height: 150px;
    object-fit: cover;
  }
}

@media screen and (max-width: 1112px) {
  .landing {
    height: 100%;
    max-height: 400px;
  }
  .questions img {
    width: 126px;
    height: 113px;
  }
  .detailsText {
    bottom: 10px;
    right: 10px;
    left: auto;
    width: 237px;
    height: 93px;
    border-radius: 10px;
  }
  #pay h3,
  #delivery h3 {
    margin: 17.71px 0 0 19px;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 100%;
    /* identical to box height, or 17px */

    color: #191919;
  }
  #pay p,
  #delivery p {
    width: 200px;
    margin: 6.29px 0 0 19px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    color: #191919;
  }
}
@media screen and (max-width: 800px) {
  .container {
    margin-top: 45px;
  }
  .landing {
    height: 100%;
    max-height: 400px;
  }
  .questions img {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .landing {
    height: 100%;
    max-height: 320px;
  }
}

@media (max-width: 550px) {
  #certs {
    font-size: 40px;
    margin-left: 1%;
  }
  .certificates-table {
    padding: 0px 1%;
  }
  /* body {
        background-color: #E4E9EB;
    } */
  .container {
    margin-top: 45px;
  }
  .loginPage {
    text-align: center;
    width: 100%;
    height: 100%;
  }
  .loginPage form {
    margin-left: 0%;
    margin-top: 15%;
    padding-bottom: 5%;
    height: 70%;
    width: 100%;
    border-radius: 16px;
    border: #999 solid 1px;
  }
  .loginPage input {
    width: 99%;
  }
  .loginPage h2 {
    font-size: 18px;
    padding-bottom: 5%;
  }
  .loginPage p {
    font-size: 12px;
  }
  .loginPage a {
    font-size: 12px;
    color: #2e8ebe;
  }
  .optionsItem {
    text-align: center;
    width: 95%;
    margin: 2.5%;
    margin-bottom: 40px;
    font-size: 12px;
    background-color: #fff;
    border-radius: 11px;
    transition: box-shadow 0.3s ease-in-out;

    /* box-shadow: 10px 6px 4px rgba(138, 135, 135, 0.281); */
    /* perspective: 1000px; Remove this if you don't want the 3D effect */
  }

  .optionsTableHeader {
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .optionsTableHeader h1 {
    font-size: 24px;
  }
  .row {
    border-radius: 30px;
    display: block;
    width: 100%;
  }

  .column {
    border-radius: 30px;
    display: block;
    flex: 1;
  }
  .optionsItem h2 {
    margin-left: 2.5%;
    padding-bottom: 3%;
    font-weight: 500;
    color: #383838;
  }
  .item button {
    width: 90%;
    border: 0;
    border-radius: 11px;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 2.5;
    outline: transparent;
    text-align: center;
    text-decoration: none;
    transition: box-shadow 0.2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    /* white-space: nowrap; */
    margin-bottom: 5%;
    margin-right: 5%;
    margin-left: 5%;
  }

  .item button a {
    display: block;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .nxt_stp_btn {
    height: 45px;
    width: 165px;
    background: #0071e3;
    border-radius: 5px;
    text-align: center;
  }

  .item_wide {
    min-width: 0;
    width: 100%;
    margin: 0;
    font-size: 30px;
    text-align: center;
    border-radius: 30px;
    box-shadow: none;
    /* transition: box-shadow 0.3s ease-in-out;
        box-shadow: 10px 6px 4px rgba(138, 135, 135, 0.281); */
    margin-bottom: 10%;
  }

  .item_wide img {
    border-radius: 30px;
    /* border-right:#8c8c8c solid 2px ; */
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 15px;
  }

  .item_wide div {
    padding: 0;
  }

  .description {
    width: 97%;
    margin-left: 3%;
  }

  .colorPicker,
  .memoryPicker,
  .secondOptPicker {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
  }

  .colorPicker input {
    display: none;
  }

  .colorPicker li,
  .memoryPicker li {
    margin-right: 8px;
    width: 80px;
    display: inline-block;
    /* margin-bottom: 1%; */
  }
  .memoryPicker li,
  #secondOptPicker li {
    height: 50px;
    margin-right: 8px;
  }
  .colorPicker li {
    height: auto;
    min-height: 50px;
    max-height: 80px;
  }
  .colorPicker p,
  .memoryPicker p {
    margin: 0;
    padding-top: 0.5px;
  }
  .colorPicker label p,
  .memoryPicker label p {
    padding-top: 0px;
  }
  .colorPicker img {
    border-radius: 0;
    width: 50px;
    height: auto;
    object-fit: cover;
    margin: 2px;
    padding: auto;
    vertical-align: middle;
  }
  /* .colorPicker label{
          position: relative;
          display: inline-block;
    }
    .colorPicker span{
        display: inline;
          position: relative;
          color: #606065c1;
    } */
  /* .colorPicker span:hover{
          position: relative;
          color: #606065fa;
    } */
  /* .title {
        position: absolute;
        left: 0;
        top: 77px;
          width: 90%;
          margin-left: 5%;
          margin-right: 5%; 
          text-align: left;
    } */
  #price {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 12px */

    color: #191919;
  }
  .title h2 {
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 100%;
    /* or 17px */

    color: #191919;
  }
  .title h4 {
    font-size: 20px;
    margin-bottom: 2%;
    color: rgba(0, 0, 0, 0.524);
  }
  .addButton {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .next_button {
    width: 100%;
    text-align: center;
  }
  .next_button button {
    width: 50%;
    text-align: center;
  }

  .carousel {
    display: flex;
    overflow-x: auto;
    -ms-overflow-style: none;
    /* Hide the scrollbar for MS Edge */
    scrollbar-width: none;
    /* Hide the scrollbar for Mozilla Firefox */

    width: 98%;
    margin-left: 2%;
    height: 420px;
  }

  .itemCard {
    max-height: 380px;
    width: 250px;
    background-color: rgb(245, 245, 247);
    margin-left: 1%;
    margin-right: 20px;
    border-radius: 11px;
    padding: 0;
    height: 100%;

    display: block;
    overflow: hidden;
  }
  .itemCard h1 {
    padding-top: 15px;
    font-size: 32px;
    margin-left: 7px;
  }
  .itemCard p {
    /* color: rgb(161, 181, 200); */
    margin-left: 7px;
    font-size: 22px;
  }
  .itemCard img {
    margin: 5%;
    width: 95%;
  }

  .landing {
    width: 100%;

    max-height: 927px;
    height: 90vh;
  }
  #landing_text {
    text-align: left;
  }
  .landing h1 {
    margin-top: 0;
    padding-top: 5%;

    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 100%;
    /* or 34px */
    color: #ffffff;
  }
  #offers {
    margin: 0;
    min-height: auto;
    max-height: 100vh;
    max-width: 700px;

    width: 100%;
    height: auto;
  }
  #promoLeft,
  #promoRight {
    margin: 1px;
    height: 40vh;
    /* max-height: 366px; */
  }

  #promoLeft h1,
  #promoRight h1 {
    margin-top: 0px;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 100%;
    /* identical to box height, or 28px */

    color: #ffffff;
    padding-top: 24px;
    margin-left: 25px;
  }
  #promoLeft h4,
  #promoRight h4 {
    margin-top: 10px;
    margin-left: 25px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */

    color: #ffffff;
  }
  #promoLeft p,
  #promoRight p {
    margin-top: 10px;
    margin-left: 25px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    color: #ffffff;
  }
  #promoLeft a,
  #promoRight a {
    margin-top: 12.5%;
    margin-left: 25px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
    text-decoration-line: underline;
    color: #0071e3;
  }
  .catalog {
    position: relative;
  }

  /* Catalog styles */
  .catalog {
    margin-top: 2px;
    border-radius: 20px;
    height: auto;
    max-height: 1061px;
  }
  .catalog h1 {
    margin: 0;
    padding-top: 3.25%;
    padding-left: 2.6%;
    padding-bottom: 2.6%;

    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 100%;
    /* identical to box height, or 60px */

    color: #191919;
  }
  .catalogTable {
    width: 100%;
    /* margin-left: 2.6%;
        margin-right: 2.6%; */
  }
  .catalog table {
    width: 100%;
  }
  .catalog td {
    width: 25%;
    max-width: 324px;
  }
  .cardTall {
    background-color: #000;
    width: 100%;
    height: 512px;
    max-width: 324px;
    border-radius: 20px;
    position: relative;
  }
  .cardTall h2,
  .cardShort h2,
  .cardLong h2 {
    margin: 0;
    padding: 25px;

    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 100%;
    /* identical to box height, or 28px */

    color: #ffffff;
  }
  .cardTall img {
    width: 100%;
  }
  .cardShort,
  .cardLong {
    width: 100%;
    background: #000;
    height: 255px;
    border-radius: 30px;
    position: relative;
  }
  #airpods img,
  #watch img,
  #iPad img,
  #mac img,
  #iPhone img {
    position: absolute;
    bottom: 0;
  }
  #iPhone img {
    right: 0;
    width: 80%;
  }
  #airpods img {
    height: 60%;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
  #watch img {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
  #iPad img {
    margin: 0;
    width: 70%;
    border-radius: 20px;
    right: 0;
  }
  #mac img {
    height: 60%;
    width: auto;
    margin: 5%;
    right: 0;
  }

  .history {
    margin-top: 2px;
    border-radius: 20px;
    height: 500px;
    background-color: rgb(212, 230, 245);
    position: relative;
  }
  .historyHeader {
    margin: 0;
    padding-top: 42px;
    padding-right: 0;
    width: 100%;
  }
  .historyHeader h1 {
    text-align: left;
    margin: 0;
    margin-left: 14px;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 90%;
    /* or 54px */
  }
  .historyText {
    min-width: 352px;
    width: 70%;
    margin-top: 5%;
    margin-left: 2.5%;
  }
  .historyText p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */
  }
  .history img {
    width: 50%;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    right: 3.5%;
  }

  #details {
    height: 610px;
    min-height: 610px;
    max-height: 700px;
    border-radius: 20px;
    margin-top: 2px;
  }
  .detailsText {
    position: absolute;
    bottom: 1%;
    left: 2.5%;
  }
  #pay {
    height: 290px;
    border-radius: 20px;
    width: 100%;
    margin-right: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url(./assets/css/backgrounds/Rectangle389.png);
    position: relative;
  }
  #delivery {
    margin-top: 1px;
    height: 290px;
    border-radius: 20px;
    width: 100%;
    margin-left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url(./assets/css/backgrounds/truck.png);
    position: relative;
  }
  #pay h3,
  #delivery h3 {
    margin: 0;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 100%;
    /* identical to box height, or 35px */

    color: #ffffff;
  }
  #pay p,
  #delivery p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    /* identical to box height, or 20px */

    color: #ffffff;
  }
  #catalog {
    text-align: center;
    margin: 2.5%;
    padding-bottom: 10px;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 100%;
    /* identical to box height, or 60px */

    color: #000000;
  }
  .list {
    margin: 0;
  }
  .order_info p {
    width: 100%;
  }
  .cart {
    min-height: 70vh;
  }

  .cart h1 {
    font-size: 60px;
  }
  .modelNameTD {
    width: 55%;
  }
  .cl-btn-9 {
    right: 3.5%;
    top: 55px;
  }
  .communication p {
    width: fit-content;
    display: block;
  }
  .communication {
    padding-top: 34px;
    margin-left: 2.5%;
  }
  .communication h1 {
    margin-top: 0;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 90%;
    /* or 31px */

    color: #ffffff;
  }

  .item-page-buttons {
    margin-top: 0px;
    width: 100%;
    text-align: left;
  }
  #buy-now {
    height: 40px;
  }
}

@media (max-width: 500px) {
  .landing {
    height: 480px;
    text-align: center;
  }
  .landing h1 {
    margin: 0;
    padding: 27px 5% 20px 5%;
    text-align: center;
    font-weight: 400;
    font-size: 42px;
    line-height: 80%;
    color: #ffffff;
  }
  #landing_text {
    text-align: center;
  }
  #landing-p-text {
    width: 289px;
    margin: auto;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */
    text-align: center;
    color: #ffffff;
  }
  .landing button {
    position: absolute;
    width: 60%;
    margin: 0 20%;
    bottom: 20px;
  }
  .landing button:hover {
    position: absolute;
    width: 60%;
    margin: 0 20%;
    bottom: 20px;
    background-color: rgba(255, 255, 255, 0.772);
  }
  .landing button a {
    width: 100%;
    height: 100%;
    color: #0071e3;
  }
  #promoLeft h1,
  #promoRight h1 {
    margin-left: 29px;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 100%;
    /* identical to box height, or 22px */

    color: #ffffff;
  }
  #promoLeft h4,
  #promoRight h4 {
    margin-left: 29px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
  }
  #promoLeft p,
  #promoRight p {
    margin-left: 29px;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
  }
  #promoLeft div,
  #promoRight div {
    margin: 12px 0 0 29px;
    padding-top: 12px;
  }
  #promoLeft a,
  #promoRight a {
    margin: 0;
  }

  .catalog {
    text-align: center;
    height: 901px;
  }
  .catalog h1 {
    padding-top: 46px;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 100%;
    /* identical to box height, or 35px */
    text-align: center;

    color: #191919;
  }
  .cardTall,
  .cardShort {
    text-align: left;
    height: 200px;
    width: 100%;
    margin-bottom: 1px;
    border-radius: 10px;
  }
  .cardTall {
    max-width: none;
  }
  .cardTall h2,
  .cardShort h2,
  .cardLong h2 {
    max-width: 150px;
    margin: 0;
    padding: 17px 0 0 17px;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 100%;
    /* identical to box height, or 17px */

    color: #ffffff;
  }
  .cardLong {
    text-align: left;
    border-radius: 10px;
    height: 200px;
    width: 99%;
    margin: 1px 0.5% 1px 0.5%;
  }
  /* #shell img {
        max-width: 60px;
        max-height: 156px;
        object-fit: cover;
    } */
  #mercedes img,
  #shell img {
    margin: 5% 35% 5% 35%;
    width: 30%;
    height: auto;
    object-fit: cover;
  }
  #partners img {
    bottom: 10%;
    margin: 5% 25% 5% 25%;
    width: 50%;
    height: auto;
  }
  .history {
    height: 545px;
    text-align: center;
  }
  .historyHeader {
    width: 349px;
    margin: 1px auto 0 auto;
    padding-top: 44px;
  }
  .historyHeader h1 {
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 80%;
    /* or 28px */
    text-align: center;
  }
  .historyText {
    width: 100%;
    min-width: 249px;
    max-width: 249px;
    margin: 20px auto 16px auto;
  }
  .historyText p {
    max-width: 249px;
    margin: auto;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */
    text-align: center;
  }
  .historyText a {
    padding-top: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    text-align: center;
    text-decoration-line: underline;
  }
  .history img {
    text-align: center;
    bottom: 1px;
    left: calc(50% - 276px / 2);
    right: auto;
    width: 276px;
    height: 200px;

    object-fit: cover;
  }
  .detailsText {
    bottom: 10px;
    right: 10px;
    left: auto;
    width: 237px;
    height: 93px;
    border-radius: 10px;
  }
  #pay h3,
  #delivery h3 {
    margin: 17.71px 0 0 19px;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 100%;
    /* identical to box height, or 17px */

    color: #191919;
  }
  #pay p,
  #delivery p {
    width: 200px;
    margin: 6.29px 0 0 19px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    color: #191919;
  }
  .questions {
    height: 448px;
    max-height: 450px;
    text-align: center;
  }
  .questions h1 {
    margin: auto;
    padding-top: 43px;
    width: calc(100% - 30px);

    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 80%;
    /* or 28px */
    text-align: center;
  }
  #questions-left-column p {
    margin: auto;
    padding-top: 29px;
    width: calc(100% - 136px);
    max-width: 229px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */
    text-align: center;
  }
  .questions img {
    display: none;
  }
  .mapText {
    width: 237px;
    height: 146px;
  }
  .mapText h3 {
    margin: 0;
    padding: 16.71px 0 0 19px;

    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 100%;
    /* or 22px */

    color: #191919;
  }
  .mapText p,
  .mapText a {
    margin: 0;
    padding: 6px 0 0 19px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    /* or 19px */

    color: #000000;
  }
  #footer-desktop {
    display: none;
  }
  #footer-mobile {
    display: block;
  }
  .footer-menu-mobile {
    position: relative;
    text-align: center;
    height: 525px;
    width: 100%;
    background: #191919;
    border-radius: 20px;
  }
  .footer-menu-mobile h2 {
    margin: auto;
    padding-top: 45px;
    width: calc(100% - 26px);
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 90%;
    /* or 31px */
    text-align: center;

    color: #ffffff;
  }
  .footer-menu-mobile h3 a {
    color: #00000091;
  }
  .footer-menu-mobile h3 {
    width: 173px;
    margin: auto;
    margin-top: 30px;
    padding: 8px 10px 8px 10px;
    background: rgb(212, 230, 245);
    border-radius: 20px;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 100%;
    color: #00000091;
  }
  #footer-menu-mobile-h3-addr {
    width: 295px;
  }
  #footer-mobile-credentials {
    margin: auto;
    margin-top: 25px;
    width: 98%;
    max-width: 400px;
    text-align: center;
  }
  #footer-mobile-credentials div {
    margin-bottom: 8px;
  }

  #footer-mobile-credentials a {
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    text-align: center;

    color: #cfcfcf;

    opacity: 0.8;
  }
  #footer-mobile-ippymarket {
    margin: 0;
    font-family: "Michroma";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 1;
  }

  #footer-mobile-links {
    margin: auto;
    margin-top: 12px;
    width: 98%;
    max-width: 400px;
    text-align: center;
  }
  #footer-mobile-links div {
    margin: 0px auto 20px auto;
  }
  #footer-mobile-links a {
    height: 17px;

    text-decoration: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-align: center;

    color: #ffffff;

    opacity: 0.8;
  }
  .cart h1,
  .reviews h1 {
    margin: 0;
    padding: 27px 0 37px 0;
    width: 100%;
    text-align: center;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 80%;
    /* identical to box height, or 34px */
    text-align: center;

    color: #000000;
  }
  .cartPrice h4 {
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 100%;
    /* identical to box height, or 17px */

    color: #191919;
  }
  .cartButton {
    text-align: center;
  }
  #next {
    margin-left: 0;
    margin-top: 28px;
  }
  .review-card {
    width: 310px;
    margin: 0 0 0 10px;
  }
  .review-card-video-block {
    display: none;
  }
  .review-card-text-block {
    width: 310px;
    height: 367px;
  }
  .review-card-text-block h2 {
    padding: 27px 0 0 28px;

    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 100%;
    /* identical to box height, or 17px */

    color: #ffffff;
  }
  .review-card-text-block p {
    padding: 0;
    margin: 14px 22px auto 28px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* or 18px */

    color: #ffffff;
  }
  .review-card-text-block p a {
    display: block;
    margin: 14px 22px auto 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    text-decoration-line: underline;

    color: #0071e3;
  }
  #slide-1,
  #slide-2,
  #slide-3 {
    width: 640px;
  }
  #buy-now {
    width: 165px;
    border: 1px solid #0071e3;
    background-color: #0071e3;
    text-align: center;
    border-radius: 5px;
    padding-top: 11px;
    padding-bottom: 12px;
    margin-right: 0px;

    color: #ffffff;
    display: inline-block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  #buy-now:hover {
    background-color: white;
    border: 1px solid #0071e3;
    color: #0071e3;
  }
  /* #buy-now a:hover {
        color: #0071E3;
    }
     */
  .order_info p,
  .order_info h5 {
    text-align: left;
  }
  #add-to-cart {
    width: 194px;
  }
  #promoRight img {
    position: absolute;
    object-fit: cover;
    height: 60%;
    width: auto;
    bottom: 0%;
    right: 12px;
    border-bottom-right-radius: 10px;
  }
}
.overlay {
  width: 100%;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f0f0f0;
  transition: opacity 1s;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  overflow: auto;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}
#container-popup-block {
  background-color: #fff;
  border-radius: 20px;
}

.popup {
  width: 100%;

  z-index: 2;
  position: relative;
  align-items: left;
}
#popup-right-column {
  position: relative;
}

.content {
  max-height: 100%;
  overflow: auto;
}

.input_form {
  margin-left: 36px;
}

.input_form h1 {
  margin: 0;
  padding: 30px 0 34px 0;
  font-size: 60px;
}

.input_form {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #fff;
  width: 100%;
  max-width: calc(1380px / 2);
}

.popup form p {
  color: #191919;
  margin-bottom: 2px;
  margin-top: 8px;
}

.popup form > div {
  display: inline;
  position: relative;
  overflow: hidden;
}

.popup form input,
.popup form textarea {
  max-width: 330px;
  width: calc(50% - 15px);
  height: 45px;

  margin: 0 11px 15px 4px;
  border: 1px solid #191919;
  border-radius: 5px;

  background: none;
  position: relative;
  z-index: 1;
  outline: 0;

  padding-left: 13px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;

  color: #868686;
}
#comment {
  height: 100px;
  width: calc(100% - 15px);
  max-width: 675px;
}

.deliveryPicker {
  width: 95%;
  margin-right: 5%;
  margin-top: 35px;
  /* max-width: 950px;
  margin-left: 3.25%;
  padding-top: 6.5%;
  width: 100%; */
  padding: 0;
  display: inline;
  text-align: left;
}
.deliveryPicker input {
  max-width: 80px;
  display: none;
}

.deliveryPicker li {
  text-align: center;
  /* background: #F7F7F7; */
  border: 1px solid rgb(209, 209, 209);
  border-radius: 4px;
  width: calc(50% - 15px);
  max-width: 330px;
  height: 40px;
  float: left;
  display: inline;
  margin: 0 11px 15px 4px;
}
.deliveryPicker {
  width: 100%;
  margin: 0;
  margin-bottom: 18px;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */
  color: #191919;
}
.deliveryPicker label {
  text-align: center;
  position: relative;
}
.deliveryPicker label p {
  padding-top: 10px;
  margin: 0;
}
.deliveryPicker span {
  vertical-align: middle;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  text-align: center;

  color: rgb(209, 209, 209);
}

.deliveryPicker input:checked + label span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  text-align: center;

  color: #ffffff;
}

.popup form input:valid,
.popup form textarea:valid {
  background: white;
  color: #191919;
}

.popup form textarea {
  background-color: #fff;
  display: block;
  resize: vertical;
}

.input_form p {
  font-size: 22px;
  color: #191919;
}

.main-block_buttons button {
  border: #ffffff;
  background-color: #ffffff;
}

.cancel_form {
  margin-left: 1%;
  width: 30%;
  border-radius: 16px;
  font-size: 16px;
}

#popup2 img {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

.transfer {
  padding: 0 2% 0 2%;
  z-index: 2;
}

#loginSuggestion {
  margin-left: 1.5%;
  font-size: 16px;
}
#loginSuggestion a {
  color: #0071e3;
}
#loginSuggestion p {
  color: rgba(0, 0, 0, 0.699);
}

.cashback {
  height: 50px;
  text-align: center;
  width: 100%;
}
.cashback table {
  width: 100%;
}
.cashback label,
.cashback p {
  font-size: 16px;
  width: auto;
}
.cashback label {
  padding-bottom: 25px;
  top: -10px;
  bottom: 0;
  width: auto;
}

#cashback_label {
  color: #0071e3;
}
#cashback_label:hover {
  color: #18415591;
}

.cashback input {
  display: none;
  width: 0;
  margin: 0;
  padding: 0;
}

/* index form */
#questions-right-column form {
  margin-top: 61px;
  margin-left: 0;
}
#questions-right-column form p {
  color: #00000075;
  margin-bottom: 2px;
  margin-top: 8px;
}

#questions-right-column form > div {
  position: relative;
  overflow: hidden;
}

#questions-right-column form input,
form textarea {
  max-width: 432px;
  width: 80%;
  height: 45px;
  margin: 0 0 15px 0;
  padding-left: 10px;
  background-color: rgb(212, 230, 245);
  border: 1px solid #00000075;
  border-radius: 5px;

  font-size: 14px;
  line-height: 17px;
}
#questions-right-column form input:valid,
#questions-right-column form textarea:valid {
  background: white;
}

#questions-right-column form input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #00000075;
  vertical-align: middle;
}
#questions-right-column form textarea {
  background-color: #fff;
  display: block;
  resize: vertical;
  font-size: 32px;
}
#questions-right-column button {
  width: 80%;
  height: 45px;
  max-width: 432px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  align-items: center;
  text-align: center;

  display: table-cell;
  vertical-align: middle;

  border: none;

  color: #0071e3;
  background: #ffffff;
  border-radius: 5px;
}
/* end of index form */
.checkout {
  position: relative;
  margin: 110px 40px 0 10%;
  width: calc(90% - 40px);
  height: 100%;
}
.checkout-th {
  position: relative;
  margin: 32px 0px 0 40px;
  width: 90%;
  height: 100%;
}
.info-th {
  position: relative;
  margin: 32px 0px 0 40px;
  width: 90%;
  height: 100%;
}
.checkout h2,
.checkout-th h2,
.info-th h2 {
  margin: 0 0 22px 60px;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */

  color: #191919;
}
.info-th h2 {
  margin: 0 0 22px 0px;
}
.info-th p {
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 12px */

  color: #191919;
}
.checkout-th h2 {
  margin: 0 0 22px 0px;
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */

  color: #191919;
}
.checkout ul {
  padding-left: 60px;
}
.checkout-th ul {
  padding-left: 0px;
}
.checkout table,
.checkout-th table {
  width: 100%;
}
.checkout table tr,
.checkout-th table tr {
  height: 80px;
}
.checkout p,
.checkout-th p {
  font-family: "Evolventa";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  /* or 18px */

  color: #191919;
}
.checkout img,
.checkout-th img {
  width: 50px;
  max-height: 60px;
  height: auto;
  object-fit: cover;
}
.checkout-img-td,
.checkout-th-img-td {
  margin: 0;
  width: 90px;
}
.checkout h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */

  color: #191919;
}
.info-th h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  color: #0071e3;
}
.info-th a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */
  text-decoration-line: underline;
  color: #000000;
}

#nxt_stp_btn {
  height: 45px;
  font-size: 16px;
  padding: 12px 0;
  margin-left: 3px;
  font-weight: 500;
  margin-bottom: 15px;
  border-radius: 5px;
  width: calc(100% - 13px);
  max-width: 675px;
  height: 45px;
  vertical-align: middle;
  color: #fff;
  background: #0071e3;
  border: 1px solid #0071e3;
}
#nxt_stp_btn:hover:enabled {
  width: calc(100% - 13px);
  max-width: 675px;
  height: 45px;
  vertical-align: middle;
  color: #0071e3;
  background: #fff;
  border: 1px solid #0071e3;
}

@media (max-width: 700px) {
  #popup-right-column {
    display: none;
  }

  .input_form {
    overflow: hidden;
    margin: 40px 1% 40px 1%;
    width: 98%;
    border-radius: 20px;
  }
  .popup form input,
  .popup form textarea {
    max-width: 700px;
    width: 98%;
    height: 45px;
    margin: 0 1% 15px 1%;
  }
  #comment {
    max-width: 700px;
    width: 98%;
    height: 80px;
    margin: 0 1% 15px 1%;
  }
  .deliveryPicker li {
    max-width: 700px;
    width: 48%;
    margin: 0 1% 15px 1%;
  }
}
@media (max-width: 500px) {
  #popup-right-column {
    display: none;
  }
  .go-bottom h1 {
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 80%;
    /* or 34px */
    text-align: center;

    color: #000000;
  }

  #questions-right-column form {
    margin-top: 15px;
  }
  #questions-right-column form input,
  #questions-right-column form textarea,
  #questions-right-column button {
    width: calc(100% - 30px);
  }
  #questions-right-column form input {
    background-color: rgba(212, 230, 245, 0.752);
    padding-left: 10px;
  }
  #questions-right-column p {
    width: 292px;
    margin-left: 15px;
    text-align: left;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 160%;
    /* or 16px */

    color: #ffffff;
  }
  #nxt_stp_btn {
    width: 97%;
    background: #0071e3;
    border-radius: 5px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    align-items: center;
    text-align: center;

    color: #ffffff;
  }
  #confirmation-label {
    margin: 37px 5% 0 5%;
    width: 90%;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 80%;
    /* or 34px */
    text-align: center;

    color: #000000;
  }
  .info-th {
    position: relative;
    margin: 37px 1% 0 1%;
    width: 98%;
    height: 100%;
  }
  .info-th h2 {
    margin: 0 0 22px 15px;
  }
  .info-th p {
    margin-left: 15px;
    font-family: "Evolventa";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    color: #191919;
  }

  .info-th h5 {
    margin-left: 15px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;

    color: #0071e3;
  }
  .info-th a {
    margin-left: 15px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    text-decoration-line: underline;
    color: #000000;
  }
}
</style>
