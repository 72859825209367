<template>
    <div class="footerMenu" id="footer-desktop">
      <div class="communication">
        <h1>Свяжитесь с нами, <br> если остались вопросы!</h1>
        <p>+7 (985) 846-24-33</p>
        <a href="mailto:sales@shell-rus.ru">sales@shell-rus.ru</a>
        <p>г. Москва ул. Свободы, 35с23</p>
      </div>
      <br>
      <div class="footerTable">
        <table>
          <tr>
            <td id="firstColumn">
              <a href="/" id="label">Shell-RUS</a>
              <a href="/privacy" id="privacyRef">Политика конфиденциальности</a>
            </td>
            <td id="secondColumn">
              <a href="/shell">Каталог</a>
              <a href="/cert">Сертификаты</a>
            </td>
            <td id="thirdColumn">
              <a href="/">Доставка</a>
              <a href="/">Оплата</a>
            </td>
            <td>
              <a href="https://wa.me/79858462433">WhatsApp</a>
              <a href="https://t.me/+79858462433">Telegram</a>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="footer-menu-mobile" id="footer-mobile">
      <div><h2>Свяжитесь с нами, <br> если остались <br> вопросы!</h2></div>
      <h3><a href="tel:+79858462433">+7 (985) 846-24-33</a></h3>
      <h3><a href="mailto:sales@shell-rus.ru">sales@shell-rus.ru</a></h3>
      <h3 id="footer-menu-mobile-h3-addr">г. Москва ул. Свободы, 35с23</h3>

      <div id="footer-mobile-credentials">
        <div> <p href="/" id="footer-mobile-ippymarket">Shell-RUS</p></div>
        <div><a href="/pp">Политика конфиденциальности</a></div>
      </div>
      <div id="footer-mobile-links">
        <div><a href="/shell">Каталог</a></div>
        <div><a href="/cert">Сертификаты</a></div>
        <div><a href="/#2011">О нас</a></div>
        <div><a href="/#delivery">Доставка</a></div>
        <div><a href="/#pay">Оплата</a></div>
      </div>
    </div>
</template>
<style>
.footerMenu {
    text-align: left;
    margin: 1% auto;
    max-width: 1380px;
}
</style>