<template>
    <div class="map">
        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A9cfb9d160f212ef1a8b655f1cb9f193ea84577e0434fef3f9ef9afe468eae383&amp;source=constructor" width="calc(100% - 15px)" height="500" frameborder="0"></iframe>       
        <div class="mapText">
            <h3>Контакты</h3>
            <p>г. Москва <br>ул. Свободы, 35с23</p>
            <p>Ежедневно с 10-22 без выходных </p>
            <a href="tel:+79858462433">+7 (985) 846-24-33</a>
        </div>
    </div>
</template>