<template>
  <div class="container"> 
    <div class="landing">
      <div class="row">
        <div class="column" id="landing_text">
          <h1>Гарантия лучших цен на оригинальные масла </h1>
          <p id="landing-p-text">Наша компания импортирует с лицензионного SHELL завода-изготовителя в Турции и реализует в России оригинальные масла Shell.</p>
          <a href="#2011"><button class="button_white">Подробнее</button></a>
        </div>
        <div class="column"></div>
      </div>
    </div>
    <OffersSection/>
    <div class="catalog">
      <h1>Разделы</h1>
      <div class="catalogTable">
        <div class='row'>
          <div class="column">
            <div class="row">
              <div class="column">
                <a href="/cert">
                  <div class="cardTall" id="shell">
                    <div width="150px"><h2>Сертификаты</h2></div>
                    <img :src="require('@/assets/images/550026329_shell_mineralnoe_gidravlicheskoe_maslo_tellus_s2_m_46_209l.png')">
                  </div>
                </a>
              </div>
              <div class="column">
                <a href="/partners">
                  <div class="cardTall" id="partners">
                    <div width="150px"><h2>Наши партнёры</h2></div>
                    <img :src="require('@/assets/images/partners.png')">
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="row">
              <table>
                <tr>
                  <td>
                    <a href="/pay">
                      <div class="cardShort" id="payment-index">
                        <h2>Оплата</h2>
                      </div>
                    </a>
                  </td>
                  <td>
                    <a href="/delivery">
                      <div class="cardShort" id="delivery-index">
                        <h2>Доставка</h2>
                      </div>
                    </a>
                  </td>
                </tr>
              </table>
            </div>
            <div class="row">
              <div class="cardLong" id="more-about">
                <a @click="downloadCatalog">
                  <h2>Узнайте больше <br> о маслах Shell</h2>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="history" id="2011">
      <div class="historyHeader">
        <h1>С 2011 года мы <br> занимаемся оптовыми <br> продажами масел.</h1>
      </div>
      <div class="row">
        <div class="column">
          <br>
          <div class="historyText">
            <p>
              Имеем все необходимые сертификаты и 
              готовы документально подтвердить происхождение продукта. Остерегайтесь подделок 
              и неадекватно низких цен.
            </p>
          </div>
        </div>
        <div class="column">
          <img :src="require('@/assets/images/kisspng-forklift-pallet-jack-loader-heavy-machinery-logist-crane-5ace4ecdc30ee8.854367101523470029799.png')" alt="our-history-text">
        </div>
      </div>
    </div>
    <MapSection/>

    <!-- <div class="questions">
      <div class="row">
        <div class="column" id="questions-left-column">
          <h1>Остались <br> вопросы? </h1>
          <p>Оставьте свои контактные данные, наш менеджер свяжется с вами и ответит на все вопросы</p>
        </div>
        <div class="column" id="questions-right-column">
          <form>
            <input v-model="form.name" type="text" placeholder="Как Вас зовут?" required>
            <input v-model="form.tel" type="text" placeholder="Номер телефона" required>
          </form>
          <br>
          <button v-if="form.name != '' && form.tel != '' && form.tel.length == 12" @click="questionReq()">Отправить</button>
          <p>Нажимая на кнопку вы автоматически соглашаетесь с <a href="/privacy">правилами обработки персональных данных</a></p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import OffersSection from '@/components/OffersSection.vue';
import MapSection from '@/components/MapSection.vue';
import axios from 'axios'
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'HomeView',
  setup() {
    const form = ref({
      name: '',
      tel: '',
    })
    return {
      form,

      downloadCatalog: () => {
        axios
            .get('/api/v1/certs/catalog/', {responseType: 'blob'})
            .then(response => {
              let fileUrl = window.URL.createObjectURL(response.data);
              let fileLink = document.createElement('a');
              fileLink.href = fileUrl;
              fileLink.setAttribute('download', 'Shell-Catalog.pdf');
              document.body.appendChild(fileLink)
              fileLink.click();
            });
      },
    }
  }, 

  components: {
    OffersSection,
    MapSection,
  }
})
</script>
